@mixin visually-hidden() {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;

  border: 0;
  outline: 0;
  clip: rect(0 0 0 0);

  opacity: 0;
  overflow: hidden;

  -webkit-appearance: none;
  -moz-appearance: none;
}
