@import "mixins";

.hstack-primo {
  --h-color-gray50: #f4f4f4;
  --h-color-gray100: #d9d9d9;
  --h-color-gray200: #e8e8e8;
  --h-color-gray300: #d1d1d1;
  --h-color-gray400: #bababa;
  --h-color-gray500: #a3a3a3;
  --h-color-gray600: #818181;
  --h-color-gray700: #5e5e5e;
  --h-color-gray800: #303030;
  --h-color-gray900: #191919;

  --h-color-button-primary: var(--h-color-gray900);
  --h-color-button-primary-contract: #ffffff;

  --h-color-button-accent: #ddf1ff;
  --h-color-button-accent-contract: #054da7;
  --h-color-button-accent-outline: #addbff;

  --h-color-text-primary: var(--h-color-gray900);
  --h-color-text-secondary: var(--h-color-gray500);

  --h-color-outlined-100: var(--h-color-gray100);
  --h-color-outlined-200: var(--h-color-gray200);

  --h-font-weight-regular: 400;
  --h-font-weight-medium: 500;
  --h-font-weight-semi-bold: 600;
  --h-font-weight-bold: 700;


  .brand-area {
    padding: 16px 20px 0;

    & img {
      width: 36px;
      height: 36px;
    }
  }

  //
  .blind, .visually-hidden {
    @include visually-hidden();
  }

  .full-width {
    width: 100% !important;
  }

  .container {
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;
  }

  .input-text {
    position: relative;
    box-sizing: border-box;
    padding: 12px 16px;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    cursor: text;

    &.contained {
      background-color: white;
      border: 1px solid transparent;
    }

    &.outline {
      background-color: white;
      border: 1px solid var(--h-color-outlined-100);
    }

    &.error {
    }

    .adornment {
      color: var(--h-color-text-secondary);
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    box-sizing: border-box;

    margin: 0;
    outline: 0;
    border: 0;
    min-width: 64px;

    cursor: pointer;
    user-select: none;
    text-decoration: none;

    color: inherit;
    background-color: transparent;

    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;

    // Default
    padding: 16px 24px;
    border-radius: 4px;

    font-size: 16px;
    font-weight: var(--h-font-weight-semi-bold);
    line-height: 20px;

    &::-moz-focus-inner {
      border-style: none; // Remove Firefox dotted outline.
    }

    &.primary {
      color: var(--h-color-button-primary-contract);
      background-color: var(--h-color-button-primary);
    }

    &.accent {
      color: var(--h-color-button-accent-contract);
      background-color: var(--h-color-button-accent);
      border: 1px solid var(--h-color-button-accent-outline);
    }
  }

  .gnb {
    position: relative;
    overflow: hidden;
    padding: 0 11px;
    border-top: 1px solid var(--h-color-outlined-200);
    margin: 16px 0;

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: var(--h-color-outlined-200);
    }
  }

  .tabs {
    display: table;
    width: 100%;
    table-layout: fixed;
    white-space: nowrap;

    & > li {
      position: relative;
      display: table-cell;
      //width: 20%;
      vertical-align: top;

      font-size: 16px;
      line-height: 48px;

      &  > a {
        display: block;
        text-align: center;
        color: inherit;

        span {
          display: inline-block;
          position: relative;
          box-sizing: border-box;
          height: 48px;
          top: -1px;
        }
      }

      &.on {
        color: #191919;
        font-weight: bold;

        a > span:after {
          content: '';
          display: block;
          position: absolute;
          left: -3px;
          right: -3px;
          bottom: -1px;
          height: 1px;
          background: #191919;
          z-index: 200;
        }
      }
    }
  }
}
