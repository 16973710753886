body, html {margin:0; padding:0;}
body {font-family: -apple-system,BlinkMacSystemFont,helvetica,Apple SD Gothic Neo,sans-serif;}
//body.stop-scroll {height:100%; overflow:hidden; touch-action:none;}


div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, button,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  font-size:100%;
  font-family:inherit;
  font-weight:inherit;
  line-height:inherit;
}

li {list-style:none;}
strong {font-weight:bold;}
a {text-decoration:none;}
i, em {font-style:normal;}

fieldset {border: 0;}

input[type=text], input[type=password], input[type=tel], input[type=number] {
  -webkit-appearance:none;
}

// reset
input:not([type=radio]):not([type=checkbox]) {
  display: block;
  box-sizing: content-box;
  background: none;

  width: 100%;
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;

  font: inherit;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-input-placeholder {
    color: #d1d1d1;
  }
  &::-moz-placeholder {
    color: #d1d1d1;
  }
  &::-ms-input-placeholder {
    color: #d1d1d1;
  }
  &:focus {
    outline: 0;
  }
  &:invalid {
    box-shadow: none;
  }
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}
